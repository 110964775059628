* {
  box-sizing: border-box; /* Apply border-box to all elements */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header, .About {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drum-machine {
  margin: 20px;
  margin-left: 40px;
}

.drum-pad {
  color: red
}

.results-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.result-block {
  width: 120px;
  height: 120px;
  border: 2px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 0.85em;
  color: #666;
}

.value {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.operations {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 10px; /* Spacing between children */
  padding: 40px; /* Large border effect */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background: linear-gradient(160deg, #466a4c 0%, #354a30 100%); /* Chalkboard-esque green gradient */
  box-sizing: border-box; /* Include padding and border in the width and height */
}

.markdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px; /* Large border effect */
  box-sizing: border-box; /* Include padding and border in the width and height */
}

.editor {
  width: 300px; /* Specify the width of the textbox */
  height: 40px; /* Specify the height of the textbox */
  padding: 8px; /* Add some padding inside the textbox for better text alignment */
  border: 2px solid #ccc; /* Define a border with a light grey color */
  border-radius: 5px; /* Round the corners of the textbox */
}

.home-container {
  color: white;
}

.bg1 {
  background-color: black;
}

.home-background {
  position: relative;
  background-color: black;
  opacity: 100%;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.bmt-background-image {
  position: fixed;
  max-width: 100%;
}

.calculator {
  margin: 80px 20px 0;
  width: 200px;
}

.calculator-display {
  width: 100%;
  display: flex;
  justify-content: center;
}

.display {
  background: #dddddd;
  border: 2px #444444 solid;
  width: 200px;
  height: 60px;
  font-size: 24px;
  text-align: right;
  align-content: center;
  padding: 8px;
}

.button {
  min-height: 20px;
  min-width: 40px;
  border-radius: 8px;
  margin: 4px;
}

.button:focus {
  background-color: #fec3a6;
}

.input {
  background: orange;
}

.operation {
  background: #a0a0a0;
}

.number {
  background: #fafafa;
}

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.number-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.operations-row {
  display: flex;
  flex-direction: column;
  max-width: 25%;
  align-content: right;
}

.numbers-rows {
  display: flex;
  flex-direction: row;
  max-width: 75%;
  flex-wrap: wrap;
}
